const keys = {
  BACKSPACE: 'Backspace',
  SPACE: ' ',
  ENTER: 'Enter',
  SHIFT: 'Shift',
  ESC: 'Escape',
  DELETE: 'Delete',
  INS: 'Insert',
  HOME: 'Home',
  END: 'End',
  PAGEUP: 'PageUp',
  PAGEDOWN: 'PageDown',
  CLEAR: 'Clear',
  PAUSE: 'Pause',
  TAB: 'Tab',
  ALT: 'Alt',
  CTRL: 'Control',

  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
}

export default keys
